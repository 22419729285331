@import '../../variables.scss';

.container {
    min-width: 100%;
    background-color: $bg;
    padding: 1rem;
    margin: 1rem;

    @include breakpoint(md) {
        width: 90%;
        min-width: 90%;
    }

    &_tab {
        overflow: hidden;
        display: flex;

        button {
            flex: 1;
            float: left;
            border: none;
            border-radius: 1em;
            outline: none;
            cursor: pointer;
            padding: 14px 16px;
            transition: 0.3s;
            background-color: $bg;
            color: $dark;
          }
        
        button:hover {
            background-color: $primary;
        }
    }

    &_tabcontent {
        position: relative;
        animation: fadeEffect 1s;
        transition: ease-out;

        &_selected {
            background-color: $primary !important;
            color: $dark;
        }
    }

    @keyframes fadeEffect {
        from {opacity: 0; left: 75%;}
        to {opacity: 1; left: 0;}
    }
}