@import "./../variables.scss";

.container {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  a {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: auto;
      height: 32px;
    }
  }
}
