@import '../../variables.scss';

.section {
    display: flex;
    flex-direction: column;
    flex-direction: column-reverse;
    background-color: $bg;
    padding: 16px 8px;
    transition: 1.5s;
    
    @include breakpoint(md) {
        flex-direction: row;
        // background-color: aqua;
        padding: 32px 16px;
        transition: 1.5s;
    }

    &__contentSide {
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex: 1;
        padding: 16px;

        h1 {
            font-weight: 500;
            color: $dark;
            text-align: center;

            @include breakpoint(md) {
                font-weight: 600;
            }
        }

        h3 {
            font-weight: 300;
            color: $dark;
            margin-top: .3rem;

            @include breakpoint(md) {
                font-weight: 400;
            }
        }

        &__btnWrapper{
            display: flex;
            justify-content: center;
            margin-top: 1rem;
        }
        button {
            cursor: pointer;
            outline: none;
            padding: 0.75rem 1.25rem;
            border-radius: $borderRadius;
            font-size: 1rem;
            font-family: inherit;
            background: $primary;
            color: $dark;
            border: 1px solid transparent;
            transition: 0.3s ease all;
            max-width: 250px;

            &:hover {
                border-color: $primary;
                background: rgba($primary, 0.1);
                color: $primary;
            }
        }
    }

    &__imageSide {
        display: flex;
        justify-content: center;
        flex: 1;
    
        img {
            width: 80%;
        }
       
    }
}