@import url('https://fonts.googleapis.com/css2?family=Encode+Sans+Expanded:wght@400;700&family=Poppins:wght@400;500;600;700;800&display=swap');

$Poppins: 'Poppins', sans-serif;

// colors
$dark: #1d1d1d;
$light: #fff;
// $primary: rgb(162, 162, 246);
$primary: #f4b536;
$bg: #f6f6f6;


// spacing
$spacing-md: 16px;
$spacing-lg: 32px;

// border radius
$borderRadius: 12px;

// header height
$headerHeight: 100px;

// footer height
$footerHeight: 82px;

@mixin breakpoint($point) {
    @if $point == sm {
        // 576px
        @media (min-width: 36em) {
            @content;
        }
    }

    @if $point == md {
        // 768px
        @media (min-width: 48em) {
            @content;
        }
    }

    @if $point == lg {
        // 992px
        @media (min-width: 62em) {
            @content;
        }
    }

    @if $point == xl {
        // 1200px
        @media (min-width: 75em) {
            @content;
        }
    }
}
