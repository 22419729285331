@import "./../../variables.scss";

.container {
  background-color: #f6f6f6;
  padding: 0.5rem;
  border-radius: 1rem;

  .title {
    font-size: 1.3em;
    font-weight: 500;
    display: flex;
    justify-content: center;
  }

  hr.divider {
    border: 1px solid $primary;
    border-radius: 5px;
    margin: 0.5rem 0;
  }

  .order_info {
    margin: 0.7rem 0;

    .order_info_row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      font-weight: 500;
      padding: 0.3rem;
    }
  }

  .payments {

    .order_info_row {
      background-color: #fff;
      display: flex;
      flex-direction: row;
      cursor: pointer;
      border-radius: 1rem;
      font-weight: 500;
      padding: 0.3rem;
      margin: 0.7rem 0;
    }

    p {
      margin-left: 1rem;
    }

    input[type="radio"] {
      flex: 0;
      height: 1.5rem;
      min-width: 1.5rem;
      filter: grayscale(1);
      margin: 0 1rem;
    }

    &__buttonBlock {
      display: flex;
      justify-content: center;
    }
  }

  button {
    cursor: pointer;
    outline: none;
    padding: 0.75rem 1.25rem;
    border-radius: $borderRadius;
    font-size: 1rem;
    font-family: inherit;
    background: $primary;
    color: $dark;
    border: 1px solid transparent;
    transition: 0.3s ease all;
    min-width: 200px;
    align-self: center;

    &:hover {
      border-color: $primary;
      background: rgba($primary, 0.1);
      color: $primary;
    }
  }
}
