@import './../variables.scss';

.footer {
    background-color: $bg;
    padding: 16px 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    p {
        color: $dark;
    }

    a {
        color: $dark;
        text-decoration: none;
        margin: 0 8px;
    }
}