@import "./../../variables.scss";

.container {
  position: relative;
  display: flex;
  background-color: $light;
  border-radius: 1rem;
  margin: 1rem 0;
  padding: 1rem;

  input[type="checkbox"] {
    display: flex;
    align-self: center;
    justify-self: center;
    accent-color: $primary;
    margin-right: 1rem;
    height: 20px;
    width: 20px;
  }
  .box {
    display: flex;
    flex-direction: column;
    width: 100%;

    &__row {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 0.1rem;

      &__quantity {
        display: flex;
        flex-direction: row;

        &__numbers {
          margin: 0 1rem;
        }
      }
    }

    &__btnBloc {
      display: flex;
    }
  }
}

.deleteProduct {
  position: absolute;
  cursor: pointer;
  top: 7px;
  right: 7px;

  &:hover {
    svg {
      color: rgb(228, 20, 20);
    }
  }
  svg {
    width: 24px;
    height: 24px;
    transition: 0.3s ease all;
  }
}

.title {
  font-size: 1em;
  font-weight: 500;

  // Desktop
  @include breakpoint(md) {
    font-size: 1.3em;
  }
}

.price {
  font-weight: 500;
  color: #3c9806;
}

.btn {
  cursor: pointer;

  &:hover {
    svg {
      color: $primary;
    }
  }

  svg {
    width: 24px;
    height: 24px;
    transition: 0.3s ease all;
  }
}

.btnDisabled {
  cursor: not-allowed;

  svg {
    width: 24px;
    height: 24px;
    transition: 0.3s ease all;
  }
}
