@import "./../../variables.scss";

.title {
  font-size: 1.5em;
  font-weight: 600;
  padding-top: 1rem;
  display: flex;
  justify-content: center;
}
.container {
  min-height: calc(100vh - (#{$headerHeight} + #{$footerHeight}));
  background-color: #fff;

  .productTitle {
    font-size: 1em;
    font-weight: 500;

    // Desktop
    @include breakpoint(md) {
      font-size: 1.3em;
    }
  }

  .price {
    font-weight: 500;
    color: #3c9806;
  }

  .box {
    display: flex;
    flex-direction: column;
    width: 100%;

    &__row {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 0.1rem;

      &__quantity {
        display: flex;
        flex-direction: row;

        &__numbers {
          margin: 0 1rem;
        }
      }
    }

    &__btnBloc {
      display: flex;
    }
  }

  .mainSection {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 1rem;

    @include breakpoint(md) {
      flex-direction: row;
      .main {
        flex: 0.45;
        background-color: $bg;
        border-radius: 1rem;
        padding: 1rem;

        &__orderList {
          background-color: $light;
          margin: 1rem 0;
          padding: 0.5rem;
          border-radius: 1rem;

          @include breakpoint(md) {
            margin-bottom: 0;
          }

          &__header {
            font-size: 1.3em;
            font-weight: 500;
            display: flex;
            justify-content: center;
          }

          &__title {
            font-size: 1em;
            font-weight: 500;

            // Desktop
            @include breakpoint(md) {
              font-size: 1.3em;
            }
          }
        }
      }

      .summary {
        background-color: $bg;
        flex: 0.5;
        border-radius: 1rem;
        padding-top: 1rem;
      }
    }
    @include breakpoint(lg) {
      flex-direction: row;
      .main {
        flex: 0.65;
      }

      .summary {
        flex: 0.33;
      }
    }

    .main {
      flex: 0.65;
    }

    .summary {
      flex: 0.33;
    }
  }

  .errorBlock {
    display: none;
    padding: 1rem;
    text-align: center;
    color: red;

    ul {
      list-style-type: none;
    }
  }

  .submitButtonBlock {
    display: flex;
    justify-content: center;
    padding: 1rem;

    a {
      @extend button;
    }
  }

  button {
    cursor: pointer;
    outline: none;
    padding: 0.75rem 1.25rem;
    border-radius: $borderRadius;
    font-size: 1rem;
    font-family: inherit;
    background: $primary;
    color: $dark;
    border: 1px solid transparent;
    transition: 0.3s ease all;
    text-decoration: none;

    &:hover {
      border-color: $primary;
      background: rgba($primary, 0.1);
      color: $primary;
    }
  }

  .linkStyle {
    cursor: pointer;
    outline: none;
    padding: 0; /* Remove padding to look more like a text link */
    background: none; /* Remove background */
    border: none; /* Remove border */
    font-size: 1rem;
    font-family: inherit;
    color: $primary; /* Set text color to your primary color */
    text-decoration: underline; /* Add underline to mimic a link */
    transition: color 0.3s ease; /* Transition effect for color */
    width: 100%;

    &:hover {
        color: darken($primary, 10%); /* Darken the text color on hover */
    }
  }
  
  .apply {
    // @extend button;
    padding: 0.3rem 0.8rem;
    align-items: center;
    justify-content: center;
  }

  .payments {
    .order_info_row {
      background-color: #fff;
      display: flex;
      flex-direction: row;
      cursor: pointer;
      border-radius: 1rem;
      font-weight: 500;
      padding: 0.3rem;
      margin: 0.7rem 0;
    }

    p {
      margin-left: 1rem;
    }

    input[type="radio"] {
      flex: 0;
      height: 1.5rem;
      min-width: 1.5rem;
      filter: grayscale(1);
      margin: 0 1rem;
    }

    &__buttonBlock {
      display: flex;
      justify-content: center;
    }
  }

  .success {
    margin-top: 3rem;
    text-align: center;
    color: rgb(28, 209, 28);
    font-size: 1.5em;
  }

  // Order Summary
  .order_info {
    margin: 0.7rem 0;

    .order_info_row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      font-weight: 500;
      padding: 0.3rem;
    }
  }
}
