@import './../variables.scss';

.full_window_container {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
    height: 100%;
    width: 100%;
    top: 0;
    z-index: 9990;
    overflow: hidden;

    &__close {
        position: absolute;
        top: 2%;
        right: 5%;
        font-size: 2rem;
        font-weight: lighter;
        cursor: pointer;
        color: white;
        z-index: 9992;

        @include breakpoint(lg) {
            top: 5%;
            right: 5%;
        }

        &:hover {
            color: $primary;
        }
    }

    &__content {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        overflow: hidden;
        z-index: 9991;

        @include breakpoint(md) {
            width: 80%;
            height: 80%;
        }

        &__arrow_left {
            position: absolute;
            top: 50%;
            left: 0;
            transform: translate(0, -50%);
            font-size: 3rem;
            font-weight: lighter;
            color: white;
            cursor: pointer;
            z-index: 9993;

            @include breakpoint(lg) {
                font-size: 5rem;
            }

            &:hover {
                color: $primary;
            }
        }

        &__arrow_right {
            position: absolute;
            top: 50%;
            right: 0;
            transform: translate(0, -50%);
            font-size: 3rem;
            font-weight: lighter;
            color: white;
            cursor: pointer;
            z-index: 9993;

            @include breakpoint(lg) {
                font-size: 5rem;
            }

            &:hover {
                color: $primary;
            }
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}