@import "./../../variables.scss";
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.car_container {
  width: 100%;
  height: 100%;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;

  @include breakpoint(md) {
    padding: 0.5rem 1rem;
  }

  .label {
    font-size: 1em;
    margin: 0;
    padding: 0;
  }

  input[type="text"] {
    all: unset;
    display: flex;
    height: 2rem;
    border: 1px solid $dark;
    border-radius: 5px;
    padding: 0 1rem;
    font-size: 1em;
    // margin-top: -2px;
  }

  input[type="text"]:focus {
    outline: none;
    border: 1px solid $primary;
  }

  input[type="number"] {
    all: unset;
    display: flex;
    height: 2rem;
    border: 1px solid $dark;
    border-radius: 5px;
    padding: 0 1rem;
    font-size: 1em;
    // margin-top: -2px;
  }

  input[type="number"]:focus {
    outline: none;
    border: 1px solid $primary;
  }

  textarea {
    border: 1px solid $dark;
    border-radius: 5px;
    font-family: $Poppins;
  }

  textarea:focus {
    outline: none;
    border: 1px solid $primary;
  }

  input[type="radio"] {
    height: 1.5rem;
    min-width: 1.5rem;
    // accent-color: $primary;
    filter: grayscale(1);
  }
}

.title_block {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.price_block {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;

  &__inputs {
    // background-color: red;
    display: flex;
    flex-direction: column;

    @include breakpoint(lg) {
      flex-direction: row;
    }

    &__radio {
      display: flex;
      // background-color: blue;
      justify-content: center;
      margin-top: 0.5rem;

      @include breakpoint(lg) {
        margin-top: 2px;
      }

      &__radio_block {
        // background-color: aqua;
        display: flex;
        flex-direction: row;
        align-self: flex-start;
        align-items: center;
        margin-left: 1rem;

        p {
          padding: 0 0.5rem;
        }
      }
    }
  }
}
