@import "./../variables.scss";

.container {
  background-color: #fff;
  display: flex;
  justify-content: center;
  position: relative;
  border-radius: 0.5rem;
  width: 100%;
  height: 100%;

  img {
    border-radius: 0.5rem;
    object-fit: contain;
    width: 100%;
    height: 100%;
    max-width: 300px;
    max-height: 300px;
  }

  .nav {
    background: rgba(0, 0, 0, 0);
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 1;
    position: absolute;
    width: 100%;
    height: 100%;

    &__btn {
      display: flex;
      justify-content: center;
      align-items: center;
      background: rgba(0, 0, 0, 0.3);
      color: #fff;
      width: 2em;
      height: 2em;

      :hover {
        cursor: pointer;
        background: rgba(0, 0, 0, 0.7);
        width: 3em;
        height: 3em;
        -webkit-transition: all 0.2s;
        -moz-transition: all 0.2s;
        transition: all 0.2s;
      }

      &__hide {
        visibility: hidden;
      }
    }
  }
}
