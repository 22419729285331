@import '../../variables.scss';

.itemBlock {
    display: flex;
    background-color: $light;
    margin: 0.75rem 0;
    padding: .75rem;
    border-radius: 15px;
    flex-direction: row;
    align-items: center;

    &:hover {
        box-shadow: 1px 2px 5px 1px rgba($color: $dark, $alpha: .2);
    }

    &_contentSection {
        flex: 1;
    }

    &_iconSection {
        display: flex;
        justify-items: center;
        justify-content: center;
        flex: 0.1;
        
        img {
            max-height: 24px;
        }

        img:hover{
            cursor: pointer;
        }
    }
}