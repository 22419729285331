@import "./../../variables.scss";

.container {
  display: flex;
  flex-direction: column;
  background-color: $bg;
  border-radius: 1rem;
  margin: .1rem;
  padding: .1rem;
  width: 100%;

  @include breakpoint(md) {
    margin: 1rem .5rem;
  }

  .noListingBlock {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 30vh;
    font-size: 1.2em;
  }
}
