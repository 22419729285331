@import "./../../variables.scss";

.container {
  width: 100%;
  height: 100%;
  padding: 0.3rem;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 1rem;
    z-index: 0;
  }

  &__overBlock {
    position: absolute;
    // background-color: aquamarine;
    z-index: 5;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
    width: 93%;
    height: 93%;

    p {
      font-family: $Poppins;
      color: $dark;
      border: 2px solid white;
      padding: 0.3rem;
      border-radius: 8px;
      font-size: 14px;
      cursor: pointer;
    }
    p:hover {
      background-color: #f4b536;
      color: $dark;
      border: 1px solid $dark;
    }
  }
}
