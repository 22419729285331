@import "./../../variables.scss";

.container {
  background-color: #f6f6f6;
  padding: 0.5rem;
  border-radius: 1rem;
  margin-bottom: 1rem;
  width: 100%;

  @include breakpoint(md) {
    margin-bottom: 0;
  }

  .title {
    font-size: 1.3em;
    font-weight: 500;
    display: flex;
    justify-content: center;
  }
}