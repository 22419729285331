@import "./../../variables.scss";

.container {
  min-height: calc(100vh - (#{$headerHeight} + #{$footerHeight}));

  .mainSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    border-radius: 1rem;
    margin: 1rem;

    @include breakpoint(md) {
      flex-direction: row;
      align-items: flex-start;
    }
  }
}
