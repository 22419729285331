@import '../../variables.scss';

.section {
    min-height: 78vh;

    &__header{
        padding: 16px;
        margin: 16px 0;
        background: $bg;

        &__progress {
            display: flex;
            align-items: center;
            flex: 4;
            margin: 5px 0;

            &__wrapper {
                border: 1px solid $dark;
                width: 90%;
                border-radius: 10px;
                border: none;
            }

            @include breakpoint(md) {
                margin: 0;
            }
        }

        &__block {
            display: flex;
            flex-direction: row;
            flex: 3;

            &__select {
                flex: 3;
            }
    
            &__flag {
                display: flex;
                flex: 1;
                justify-content: space-around;
                align-items: center;

                img {
                    width: 32px;
                    transition: .2s ease-in-out;
                    cursor: pointer;
                }
                img:hover {
                    width: 36px;
                }
            }
        }

        @include breakpoint(md) {
            display: flex;
            flex-direction: row;
            margin: 32px 0;
        }
    }

    &__main {
        display: flex;
        flex-direction: column;
        margin-bottom: 3rem;

        &__question{
            background-color: $bg;
            padding: 16px;
            font-weight: 500;
            font-size: 20px;
        }

        &__answer {
            margin: 1rem 1rem 0 1rem;
            padding: 1rem;
            background: $bg;
            border-radius: .25rem;
            border: none;
            box-shadow: 0 2px 5px 0 rgba($color: $dark, $alpha: .2);
            overflow: hidden;
            transition: .5s;
        }

        &__answer:hover {
            background: $primary;
            cursor: pointer;
            box-shadow: 0 2px 5px 0 rgba($color: $primary, $alpha: .2);
        }
        
        button {
            display: flex;
            align-self: center;
            justify-content: center;
            min-width: 250px;
            cursor: pointer;
            outline: none;
            padding: 0.75rem 1.25rem;
            border-radius: $borderRadius;
            font-size: 1rem;
            font-family: inherit;
            background: $primary;
            color: $dark;
            border: 1px solid transparent;
            transition: 0.3s ease all;
            margin: 2rem 0;
    
            &:hover {
                border-color: $primary;
                background: rgba($primary, 0.1);
                color: $primary;
            }
        }
    }
}
