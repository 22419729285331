@import "./../../variables.scss";

.container {
  min-height: calc(100vh - (#{$headerHeight} + #{$footerHeight}));

  .mainSection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: $bg;
    border-radius: 1rem;
    margin: 1rem;
    padding: 1rem;
  }

  &__error {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 3rem;
  }
}
