@import '../../variables.scss';

.section {
    display: flex;
    flex-direction: column;
    background-color: $bg;
    padding: 16px 8px;
    transition: 1.5s;
    
    
    @include breakpoint(md) {
        flex-direction: row;
        padding: 32px 16px;
        transition: 1.5s;
        min-height: 500px;
    }

    &__contentSide {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex: 1;
        padding: 16px;

        h1 {
            font-weight: 600;
            color: $dark;
            text-align: center;

            @include breakpoint(md) {
                font-weight: 700;
            }
        }

        ul {
            margin: 1rem 0;
        }

        button {
            cursor: pointer;
            outline: none;
            padding: 0.75rem 1.25rem;
            border-radius: $borderRadius;
            font-size: 1rem;
            font-family: inherit;
            background: $primary;
            color: $dark;
            border: 1px solid transparent;
            transition: 0.3s ease all;
            max-width: 250px;

            &:hover {
                border-color: $primary;
                background: rgba($primary, 0.1);
                color: $primary;
            }
        }
    }

    &__imageSide {
        display: flex;
        justify-content: center;
        flex: 1;
    
        img {
            width: 80%;
        }
       
    }
}

