@import './../variables.scss';

.container {
    margin: 1rem;

    h1 {
        color: $dark;
        padding: 1rem;
        text-align: center;
    }

    h2 {
        color: $dark;
        padding: .5rem;
    }

    h3 {
        color: $dark;
        padding: .5rem;
    }
    
    p {
        color: $dark;
        padding: .3rem;
    }

    ul {
        color: $dark;
        padding: .3rem;
        list-style-position: inside;

        li {
            color: $dark;
            padding: .3rem;
        }
    }
}