@import './../variables.scss';

.popupBox {
    position: fixed;
    background-color: rgba($color: #000000, $alpha: 0.5);
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 200;
    overflow-y: auto;

    &__box {
        position: relative;
        width: 80%;
        min-height: 200px;
        background-color: $bg;
        border: 1px solid #fff;
        border-radius: 7px;
        margin: 100px auto;
        padding: 10px;
        padding-bottom: 24px;
        overflow: auto;
        display: flex;
        flex-direction: column;
        align-items: center;

        @include breakpoint(sm) {
            // This will apply to 'sm' and up
            width: 90%;
            height: auto;
        }
    
        @include breakpoint(md) {
            // This will apply to 'md' and up, overriding the 'sm' rule on 'md' screens
            flex-direction: row;
            width: 75%;
        }

        &__left {
            // flex: 1;

            img {
                width: 100%;
                padding: 12px;
                max-height: 250px;
            }
        }

        &__right {
            display: flex;
            flex-direction: column;
            flex: 1.5;

            h2 {
                text-align: center;
            }

            p {
                padding: 12px;
            }
        }
    }

    button {
        cursor: pointer;
        outline: none;
        padding: 0.75rem 1.25rem;
        border-radius: $borderRadius;
        font-size: 1rem;
        font-family: inherit;
        background: $primary;
        color: $dark;
        border: 1px solid transparent;
        transition: 0.3s ease all;
        min-width: 200px;
        align-self: center;

        &:hover {
            border-color: $primary;
            background: rgba($primary, 0.1);
            color: $primary;
        }
    }
}