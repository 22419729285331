@import "../../variables.scss";

.section {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $bg;
  padding: 16px 8px;
  transition: 1.5s;
  margin: 16px 0;

  @include breakpoint(md) {
    margin: 24px 0;
    transition: 1.5s;
  }

  h1 {
    font-weight: 600;
    color: $dark;

    @include breakpoint(md) {
      font-weight: 700;
    }
  }

  h2 {
    font-weight: 500;
    color: $dark;

    @include breakpoint(md) {
      font-weight: 500;
    }
  }

  form {
    input {
      height: 40px;
      font-size: 16px;
      border-radius: 5px;
      border: 1px solid $dark;
      min-width: 250px;
      width: 80%;
      padding: 10px;
      margin: 10px 0;
    }

    textarea {
      height: 40px;
      font-size: 16px;
      border-radius: 5px;
      border: 1px solid $dark;
      min-width: 250px;
      min-height: 80px;
      width: 100%;
      padding: 10px;
      margin: 10px 0;

      @include breakpoint(md) {
        width: 90%;
      }

      @include breakpoint(lg) {
        width: 80%;
      }
    }

    input[type="submit"] {
      cursor: pointer;
      outline: none;
      padding: 0.75rem 1.25rem;
      border-radius: $borderRadius;
      font-size: 1rem;
      font-family: inherit;
      font-weight: normal;
      background: $primary;
      color: $dark;
      border: 1px solid transparent;
      transition: 0.3s ease all;
      width: auto;
      height: auto;

      &:hover {
        border-color: $primary;
        background: rgba($primary, 0.1);
        color: $primary;
      }
    }

    p {
      color: forestgreen;
      padding: 8px 24px;
    }
    p.failed {
      color: lightcoral;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    width: 100%;

    @include breakpoint(md) {
      display: flex;
      flex-direction: row;
      padding: 16px;
      transition: 1.5s;
    }

    &__contentText {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;

      @include breakpoint(md) {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      &__contactInfo {
        width: 100%;
        padding: 16px;
      }

      &__block {
        display: flex;
        flex-direction: row;
        padding: 8px;
        align-items: center;
      }

      img {
        width: 32px;
        margin-right: 10px;
      }
    }

    &__contentForm {
      flex: 1.5;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}
