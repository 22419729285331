@import "./../../variables.scss";
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.car_detail_container {
  width: 100%;
  height: 100%;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  //   background-color: lightgreen;

  @include breakpoint(md) {
    padding: 0.5rem 1rem;
  }

  .label {
    font-size: 1em;
    margin: 0;
    padding: 0;
  }

  input[type="text"] {
    all: unset;
    display: flex;
    height: 2rem;
    border: 1px solid $dark;
    border-radius: 5px;
    padding: 0 1rem;
    font-size: 1em;
  }

  input[type="text"]:focus {
    outline: none;
    border: 1px solid $primary;
  }

  input[type="number"] {
    all: unset;
    display: flex;
    height: 2rem;
    border: 1px solid $dark;
    border-radius: 5px;
    padding: 0 1rem;
    font-size: 1em;
    // margin-top: -2px;
  }

  input[type="number"]:focus {
    outline: none;
    border: 1px solid $primary;
  }
}

.input_block {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;

  @include breakpoint(md) {
    margin: 0 1rem;
    flex: 1;
  }
}

.title {
  font-size: 1.5em;
  font-weight: 600;
  display: flex;
  justify-content: center;
}

// Make/Model
.row {
  display: flex;
  flex-direction: column;

  @include breakpoint(md) {
    flex-direction: row;
    margin-bottom: 1rem;
  }
}
