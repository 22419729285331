@import "./../../variables.scss";

.container {
  background-color: #f6f6f6;
  padding: 0.5rem;
  border-radius: 1rem;
  margin-bottom: 1rem;
  width: 100%;

  @include breakpoint(md) {
    margin-bottom: 0;
  }

  .title {
    font-size: 1.3em;
    font-weight: 500;
    display: flex;
    justify-content: center;
  }

  .basicInfo {
    display: flex;
    flex-direction: column;
    // font-weight: 500;
  }

  .listingInfo {
    display: flex;
    flex-direction: column;
  }

  .imagesSection {
    margin: 1rem 0;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    justify-content: center;
    grid-auto-columns: 250px;
    grid-auto-rows: 150px;
    grid-gap: 1rem;
    
    img {
      max-width: 100%;
      max-height: 100%;
      align-self: center;
      justify-self: center;
      border-radius: 1rem;
      object-fit: cover;
    }
  }
}
