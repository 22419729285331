@import '../../variables.scss';

.container {
    min-width: 100%;
    flex-direction: column;
    display: flex;

    @include breakpoint(md) {
        flex-direction: row;
        min-width: 90%;
        margin-top: 1rem;
    }

    button {
        cursor: pointer;
        padding: 0.75rem 1.25rem;
        border-radius: $borderRadius;
        font-size: 1rem;
        background: $primary;
        color: $dark;
        border: 1px solid transparent;
        transition: 0.3s ease all;

        &:hover {
            border-color: $primary;
            background: rgba($primary, 0.1);
            color: $primary;
        }
    }

    &_infoBlock{
        background-color: $bg;
        flex: 1;

        @include breakpoint(md) {
            margin-right: .5rem;
        }

        &_image{
            display: flex;
            justify-content: center;
            
            img {
                height:100px;
                border-radius:50%;
                -moz-border-radius:50%;
                -webkit-border-radius:50%;
                width:100px;
                margin: .8rem 0;
            }
        }

        &_content{
            text-align: center;

            &_row{
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                margin-bottom: 16px;

                img {
                    width: 24px;
                    margin: 8px;
                }
            }
        }
    }

    &_docsBlock{
        background-color: $bg;
        margin-top: 1rem;
        flex: 1;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;

        @include breakpoint(md) {
            margin-left: .5rem;
            margin-top: 0;
        }
    }

}