@import './../../variables.scss';

.closeBtn {
    position: absolute;
    padding: 2px !important;
    top: 2px;
    right: 15px;
    font-size: 1.5rem;
    cursor: pointer;
    color: $dark;
    transition: 0.2s ease all;

    &:hover {
        color: $primary;
    }
}

.popupBox {
    position: fixed;
    background-color: rgba($color: #000000, $alpha: 0.5);
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 200;
    overflow-y: auto;

    &__box {
        position: relative;
        width: 80%;
        min-height: 200px;
        background-color: $bg;
        border: 1px solid #fff;
        border-radius: 7px;
        margin: 100px auto;
        padding: 10px;
        padding-bottom: 24px;
        overflow: auto;
        display: flex;
        flex-direction: column;

        @include breakpoint(md) {
            width: 50%;
        }

        &__row {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
        }

        input[type=checkbox] {
            margin: 0 .5rem;
        }

        input[type=text] {
            margin: 1rem 0;
        }
    }

    h2 {
        text-align: center;
    }

    p {
        padding: 12px;
        text-align: center;
    }

    

    button {
        cursor: pointer;
        outline: none;
        padding: 0.75rem 1.25rem;
        border-radius: $borderRadius;
        font-size: 1rem;
        font-family: inherit;
        background: $primary;
        color: $dark;
        border: 1px solid transparent;
        transition: 0.3s ease all;
        min-width: 200px;
        align-self: center;

        &:hover {
            border-color: $primary;
            background: rgba($primary, 0.1);
            color: $primary;
        }
    }
}