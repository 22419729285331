@import "../../variables.scss";

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  margin: 1rem;
  border-radius: 1rem;
  background-color: #fff;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  @include breakpoint(md) {
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: row;
  }

  .statusDropDown {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 2rem;
    width: 100%;
    bottom: 0;
    right: 0;
    border-radius: 0.5rem;
    color: #fff;
    font-size: 0.8em;
    font-weight: 500;

    @include breakpoint(md) {
      width: calc(100% - 270px);
    }

    .viewContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 1rem;
      
      img {
        margin-left: 0.5rem;
      }

      span {
        color: $dark;
      }
    }
  }

  .viewMarketplaceContainer {
    justify-content: flex-end;
  }

  .toCheckout {
    position: absolute;
    bottom: 0;
    right: 0;
    margin: 0.1rem;
    padding: 0.5rem;
    border-radius: 0.5rem;
    background-color: $primary;
    color: $dark;
    font-size: 0.8em;
    font-weight: 500;
  }

  .statusUnderReview {
    position: absolute;
    bottom: 0;
    right: 0;
    margin: 0.1rem;
    padding: 0.5rem;
    border-radius: 0.5rem;
    color: $dark;
    font-size: 0.8em;
    font-weight: 500;
  }

  .leftSide {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    border: 1px solid lightgray;
    border-radius: 1rem;

    @include breakpoint(md) {
      margin-right: 1rem;
    }

    img {
      border-radius: 1rem;
      max-width: 250px;
      width: 250px;
      max-height: 200px;
      height: 200px;
      object-fit: cover;
    }

    &__verifyd {
      position: absolute;
      margin-top: 1rem;
      padding: 0.2rem;
      top: 0;
      left: 0;
      background-color: $primary;
    }
  }

  .rightSide {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    margin-bottom: 1.5rem;
    width: 100%;

    @include breakpoint(md) {
      margin-top: 0;
    }

    .title {
      font-size: 1.1em;
      font-weight: 400;
      display: flex;

      @include breakpoint(md) {
        font-size: 1.2em;
      }
    }

    .priceBlock {
      &__price {
        font-size: xx-large;
        font-weight: 500;
        color: #3c9806;
      }
    }

    .carInfoBlock {
      &__row {
        display: flex;
        flex-direction: column;
        margin-top: 0.3rem;

        @include breakpoint(md) {
          flex-direction: row;
          margin-top: 0;
        }
      }

      &__colOne {
        @include breakpoint(md) {
          flex: 1;
        }
      }

      &__colTwo {
        margin-top: 0.3rem;

        @include breakpoint(md) {
          margin-top: 0;
          flex: 1;
        }
      }
    }

    .locationBlock {
      margin-top: 0.3rem;

      @include breakpoint(md) {
        margin-top: 0;
      }
    }

    .listingInfoBlock {
      margin-top: 0.3rem;

      @include breakpoint(md) {
        margin-top: 0.65rem;
      }
    }
  }
}
