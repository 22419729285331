@import "./../../variables.scss";

.container {
  display: flex;
  flex-direction: column;
  background-color: $bg;
  margin: 1rem 0.5rem;
  padding: 1rem;
  border-radius: 1rem;
  width: 100%;

  @include breakpoint(md) {
    max-width: 350px;
    min-width: 350px;
    width: 350px;
  }

  button {
    display: flex;
    align-self: center;
    justify-content: center;
    min-width: 200px;
    cursor: pointer;
    outline: none;
    padding: .5rem;
    border-radius: $borderRadius;
    font-size: 1rem;
    font-family: inherit;
    background: $primary;
    color: $dark;
    border: 1px solid transparent;
    transition: 0.3s ease all;

    &:hover {
      border-color: $primary;
      background: rgba($primary, 0.1);
      color: $primary;
    }
  }

  input[type="text"] {
    all: unset;
    display: flex;
    height: 2rem;
    border: 1px solid $dark;
    border-radius: 5px;
    padding: 0 1rem;
    font-size: 1em;
  }

  input[type="text"]:focus,
  input[type="number"]:focus {
    outline: none;
    border: 1px solid $primary;
  }

  input[type="number"] {
    all: unset;
    height: 2rem;
    border: 1px solid $dark;
    border-radius: 5px;
    padding: 0 1rem;
    font-size: 1em;
    width: 100px;
  }

  .title {
    font-size: 1em;
  }

  .filterByLocationBlock {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;

    &__inputSection {
      display: flex;
      flex-direction: column;

      &__suggestedSection {
        z-index: 1;
        display: flex;
        flex-direction: column;
        width: calc(350px - 3.3rem);
        position: absolute;
        margin-top: 2.1rem;
        flex-direction: column;
        background-color: #fff;

        @include breakpoint(md) {
          width: calc(350px - 2rem);
        }

        p {
          padding: 0.5rem;
          border-bottom: 1px solid $primary;
        }
        p:hover {
          cursor: pointer;
          background-color: $primary;
        }
      }
    }
  }

  .categoryBlock {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
  }

  .filterByPriceBlock {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;

    &__inputRow {
      display: flex;
      justify-content: space-between;
    }
  }

  .carListingBlock {
    display: flex;
    flex-direction: column;

    &__row {
      margin-bottom: 1rem;

      &__inputs {
        display: flex;
        justify-content: space-between;
      }
    }
  }

  .searchBtnBlock {
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
  }
}
