@import '../../variables.scss';

.section {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(368px, 1fr));
    gap: 1rem ;
    padding: 16px 0;
    transition: 1.5s;
    margin: 8px;
    
    a {
        color: black;
        text-decoration: none;
    }

    &__card {
        padding: 1rem;
        background: $bg;
        border-radius: .25rem;
        border: none;
        box-shadow: 0 2px 5px 0 rgba($color: $dark, $alpha: .2);
        overflow: hidden;

        &__header{
            font-size: 1.5rem;
            margin-bottom: .5rem;
        }

        &__body {
            font-size: 1rem;
        }

        img {
            
            display: block;
            width: 100%;
            max-height: 150px;
            object-position: center;
            transition: 300ms transform ease-in-out;
            margin-bottom: .5rem;

            &:hover {
                transform: scale(1.025);
            }
        }
    }
}