@import "./../../variables.scss";

.title {
  font-size: 1.5em;
  font-weight: 600;
  padding-top: 1rem;
  display: flex;
  justify-content: center;
}
.container {
  min-height: calc(100vh - (#{$headerHeight} + #{$footerHeight}));
  background-color: #fff;

  .mainSection {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 1rem;

    @include breakpoint(md) {
      flex-direction: row;
      .preview {
        flex: 0.45;
      }

      .checkout {
        flex: 0.5;
      }
    }
    @include breakpoint(lg) {
      flex-direction: row;
      .preview {
        flex: 0.65;
      }

      .checkout {
        flex: 0.33;
      }
    }

    .preview {
      flex: 0.65;
    }

    .checkout {
      flex: 0.33;
    }
  }

  .errorBlock {
    display: none;
    padding: 1rem;
    text-align: center;
    color: red;

    ul {
      list-style-type: none;
    }
  }

  .submitButtonBlock {
    display: flex;
    justify-content: center;
    padding: 1rem;
  }
  
  .button {
    cursor: pointer;
    outline: none;
    padding: 0.75rem 1.25rem;
    border-radius: $borderRadius;
    font-size: 1rem;
    font-family: inherit;
    background: $primary;
    color: $dark;
    border: 1px solid transparent;
    transition: 0.3s ease all;
    text-decoration: none;

    &:hover {
      border-color: $primary;
      background: rgba($primary, 0.1);
      color: $primary;
    }
  }

  .success {
    margin-top: 3rem;
    text-align: center;
    color: rgb(28, 209, 28);
    font-size: 1.5em;
  }
}
