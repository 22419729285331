@import "./../../variables.scss";

.container {
  min-height: calc(100vh - (#{$headerHeight} + #{$footerHeight}));

  //   @include breakpoint(md) {
  //     flex-direction: row;
  //     min-width: 90%;
  //     margin-top: 1rem;
  //   }

  .title {
    font-size: 1.5em;
    font-weight: 600;
    padding-top: 1rem;
    display: flex;
    justify-content: center;
  }

  .carDetailTitle {
    font-size: 1.5em;
    font-weight: 600;
    // padding-top: 1rem;
    display: flex;
    justify-content: center;
  }

  .label {
    font-size: 1.2em;

    @include breakpoint(md) {
      flex: 1;
    }
  }

  input {
    display: flex;
    flex: 1;
    height: 2rem;
    border: 1px solid $dark;
    border-radius: 5px;
    padding: 0 1rem;
    font-size: 1em;

    @include breakpoint(md) {
      flex: 0.5;
    }
  }

  input:focus {
    outline: none;
    border: 1px solid $primary;
  }

  .buttonSave {
    display: flex;
    align-self: center;
    justify-content: center;
    // min-width: 250px;
    margin-left: 1rem;
    cursor: pointer;
    outline: none;
    padding: 0.15rem 1rem;
    border-radius: $borderRadius;
    font-size: 1rem;
    font-family: inherit;
    background: $primary;
    color: $dark;
    border: 1px solid transparent;
    transition: 0.3s ease all;
    // margin: 1rem 0;

    &:hover {
      border-color: $primary;
      background: rgba($primary, 0.1);
      color: $primary;
    }
  }

  .errorBlock {
    display: none;
    padding: 1rem;
    text-align: center;
    color: red;

    ul {
      list-style-type: none;
    }
  }

  .searchSection {
    width: 100%;
    z-index: 2;
    
    &__suggestionsBlock{
      position: absolute;
      background-color: #fff;
      display: flex;
      flex-direction: column;
      margin-left: 1rem;
      width: 200px;

      p {
        width: 100%;
        padding: 0.5rem;
        border-bottom: 1px solid $primary;
      }
      p:hover {
        cursor: pointer;
        background-color: $primary;
      }
    }
  }

  &__step1 {
    background-color: $bg;
    padding: 1rem 10px;

    @include breakpoint(md) {
      padding: 1rem;
    }

    &__categoryBlock {
      display: flex;
      flex-direction: column;
      margin-top: 1rem;

      @include breakpoint(md) {
        flex-direction: row;
        align-items: center;
      }

      &__select {
        @include breakpoint(md) {
          flex: 3;
        }
      }
    }

    &__contactInfoBlock {
      display: flex;
      flex-direction: column;
      margin-top: 1rem;

      &__nameSection {
        display: flex;
        word-break: break-all;
        align-items: center;
        margin-top: 0.5rem;
        justify-content: center;

        img {
          width: 18px;
          cursor: pointer;
          margin-left: 1rem;
        }
      }

      &__emailSection {
        display: flex;
        align-items: center;
        word-break: break-all;
        margin-top: 0.5rem;
        justify-content: center;

        img {
          width: 18px;
          cursor: pointer;
          margin-left: 1rem;
        }
      }

      &__phoneSection {
        display: flex;
        align-items: center;
        word-break: break-all;
        margin-top: 0.5rem;
        justify-content: center;

        /* Chrome, Safari, Edge, Opera */
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        /* Firefox */
        input[type="number"] {
          -moz-appearance: textfield;
        }

        img {
          width: 18px;
          cursor: pointer;
          margin-left: 1rem;
        }
      }
    }

    &__locationBlock {
      margin-top: 1rem;
      display: flex;

      @include breakpoint(md) {
        .label {
          flex: none;
        }
      }

      input {
        margin-left: 1rem;

        @include breakpoint(md) {
          max-width: 30%;
        }
      }
    }

    &__saveAndNextBlock {
      display: flex;
      justify-content: flex-end;
      margin-top: 1rem;

      button {
        padding: 0.75rem 1.25rem !important;
      }
    }
  }

  &__step2 {
    display: flex;
    flex-direction: column;
    padding: 1rem 10px;

    @include breakpoint(md) {
      padding: 1rem;
    }

    &__firstRowSection {
      display: flex;
      flex-direction: column;

      @include breakpoint(md) {
        flex-direction: row;
      }

      &__leftSection {
        width: 100%;

        @include breakpoint(md) {
          width: 50%;
        }

        &__imgSection {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin: 1rem;

          &__mainImgBlock {
            img {
              width: 100%;
              height: 100%;
              max-height: 275px;
              padding: 0.5rem;
              border: 1px solid $dark;
              border-radius: 1rem;

              @include breakpoint(md) {
                max-height: 400px;
              }
            }
          }

          &__smallIconsBlock {
            width: 100%;
            border-radius: 8px;
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(120px, 120px));
            justify-content: center;
            grid-auto-columns: 120px;
            grid-auto-rows: 120px;
            grid-gap: 1rem;

            input[type="file"] {
              display: none;
            }

            label {
              cursor: pointer;
              width: 100%;
              height: 100%;
              padding: 0.2rem;
              border-radius: 8px;
            }
          }
        }
      }

      &__rightSection {
        width: 100%;
        // background-color: lightgray;

        @include breakpoint(md) {
          width: 50%;
        }
      }
    }

    &__saveAndNextBlock {
      display: flex;
      justify-content: space-around;

      button {
        padding: 0.75rem 1.25rem !important;
      }
    }
  }
}
