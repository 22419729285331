@import './../variables.scss';

.spinner_container {
    min-height: calc(100vh - 157px);
    display: flex;
    justify-content: center;
    
    img {
        display: flex;
        align-self: center;
    }
}