@import './../../variables.scss';

.container {
    display: flex;
    margin: 1rem 0rem;
    justify-content: center;

    @include breakpoint(md) {
        // Desktop
        margin: 1rem;
    }

    &__block {
        // background-color: bisque;
        // flex: 1;
        flex-direction: row;

        &__top {
            display: flex;
            align-items: center;
        
            &__line {
                background-color: $dark;
                min-height: 2px;
                min-width: 2px;

                @include breakpoint(md) {
                    // Desktop
                    min-height: 2px;
                    min-width: 20px;
                }
            }

            &__circle {
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: $bg;
                border-radius: 50%;
                width: 40px;
                height: 40px;

                @include breakpoint(md) {
                    // Desktop
                    width: 50px;
                    height: 50px;
                }

                p {
                    color: $dark;
                }
            }

            &__circle:hover {
                background-color: $primary;
                cursor: pointer;
            }
        }
    }
}

.circle_selected {
    background-color: $primary;
}