@import '../../../variables.scss';

.popupBox {
    position: fixed;
    background-color: rgba($color: #000000, $alpha: 0.5);
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 200;
    overflow-y: auto;

    &__box {
        position: relative;
        max-width: 350px;
        min-height: 200px;
        background-color: $bg;
        border: 1px solid #fff;
        border-radius: 7px;
        margin: 70px auto;
        padding: 10px;
        padding-bottom: 24px;
        overflow: auto;
        display: flex;
        align-items: center;
        justify-content: center;

        @include breakpoint(md) {
            margin: 100px auto;
        }
    }
}