@import '../../../variables.scss';

.cardBlock {
    display: flex;
    background-color: $light;
    margin: 0.75rem 0;
    padding: .75rem;
    border-radius: 15px;
    flex-direction: row;
    align-items: center;

    &:hover {
        // cursor: pointer;
        box-shadow: 1px 2px 5px 1px rgba($color: $dark, $alpha: .2);
    }

    &_logoSection {
        display: flex;
        margin-right: 1.5rem;
        align-items: center;
        
        img {
            max-width: 48px;
            max-height: 30px;
        }
    }

    &_cardInfoSection {
        display: flex;
        flex-direction: column;

        .secondLine {
            font-size: 14px;
        }

        @include breakpoint(md) {
            flex-direction: row;
            
            .firstLine {
                margin-right: 1rem;
                min-width: 100px;
            }
    
            .secondLine {
                font-size: 16px;
            }
        }
        
    }

}