@import "./../../";

.container {
  width: 100%;
  border-radius: 1rem;
  min-height: 250px;

  h2 {
    font-family: inherit;
    font-weight: 500;
  }

  .button {
    display: flex;
    align-self: center;
    justify-content: center;
    min-width: 130px;
    cursor: pointer;
    outline: none;
    padding: 0.3rem 0;
    border-radius: $borderRadius;
    font-size: 1rem;
    font-family: inherit;
    background: $primary;
    color: $dark;
    border: 1px solid transparent;
    transition: 0.3s ease all;

    &:hover {
      border-color: $primary;
      background: rgba($primary, 0.1);
      color: $primary;
    }
  }

  .main {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 1rem;
    width: 100%;
    height: 100%;

    @include breakpoint(md) {
      // Desctop
      flex-direction: row;
      justify-content: space-between;
    }
  }

  .left {
    display: flex;
    background-color: #fff;
    justify-content: center;
    align-items: center;
    border-radius: 1rem;
    width: 100%;
    min-width: 100%;
    height: 100%;
    min-height: 200px;
    max-height: 200px;
    padding: 0.3rem;

    @include breakpoint(md) {
      width: 30%;
      min-width: 30%;
      min-height: 300px;
      max-height: 300px;
    }
  }

  .right {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    padding: 0.3rem;
    border-radius: 1rem;
    width: 100%;
    min-width: 100%;
    max-height: 300px;
    min-height: 300px;
    margin-top: 1rem;
    justify-content: space-between;

    @include breakpoint(md) {
      margin-top: 0;
      width: 67%;
      min-width: 67%;
    }

    &__main {
      display: flex;
      flex-direction: column;
      padding: 0.2rem;
      height: 200px;
      min-height: 200px;
      max-height: 200px;
      overflow-y: scroll;

      @include breakpoint(md) {
        height: 245px;
        min-height: 245px;
        max-height: 245px;
      }

      &__title {
        text-align: center;
      }

      &__description {
        margin-bottom: 0.3rem;
      }

      &__detailsList {
        margin-bottom: 0.2rem;
      }
    }

    &__bottom {
      display: flex;
      align-items: center;
      flex-direction: column;
      border-top: 1.5px solid $primary;
      padding: 0.2rem;
      height: 80px;

      @include breakpoint(md) {
        flex-direction: row;
        height: 50px;
      }

      &__price {
        display: flex;
        justify-content: space-around;
        width: 100%;
        color: #3c9806;

        @include breakpoint(md) {
          flex: 0.4;
        }
      }

      &__buttons {
        display: flex;
        justify-content: space-around;
        width: 100%;

        @include breakpoint(md) {
          flex: 0.6;
        }
      }
    }
  }
}
