@import "./../../variables.scss";

input[type="text"] {
  height: 2rem;
  border: 1px solid $dark;
  border-radius: 5px;
  padding: 0 1rem;
  font-size: 1em;
  width: 100%;
}

input[type="text"]:focus {
  outline: none;
  border: 1px solid $primary;
}

.container {
  display: flex;
  flex-direction: column;
  background-color: #f6f6f6;
  padding: 0.5rem;
  border-radius: 1rem;
  margin-bottom: 1rem;
  width: 100%;

  @include breakpoint(md) {
    margin-bottom: 0;
  }
}

.title {
  font-size: 1.3em;
  font-weight: 500;
  display: flex;
  justify-content: center;
}

.fullRow {
  margin: 0.5rem 0rem;

  @include breakpoint(md) {
    margin: 0.5rem 1rem;
  }
}

.sharedRow {
  display: flex;
  flex-direction: column;

  input[type="text"] {
    margin: 0.5rem 0rem;
  }

  @include breakpoint(md) {
    flex-direction: row;
    margin: 0rem 1rem;
    justify-content: space-between;

    input[type="text"] {
      display: flex;
      flex: 0.3;
    }
  }
}
