@import "./../../variables.scss";

.container {
    min-height: calc(100vh - (#{$headerHeight} + #{$footerHeight}));
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: $light;

    .errorMsg {
        text-align: center;
        color: #c82929;
        margin-top: -8px;
        font-size: small;
        word-break: break-all;
        max-width: 280px;
    }

    .errorInput {
        border: 1px solid #c82929;
    }

    &__form{
        display: flex;
        flex-direction: column;
        background-color: $bg;
        min-width: 100%;
        min-height: calc(100vh - (#{$headerHeight} + #{$footerHeight}) - 1rem);
        padding: 1rem;
        background-color: $bg;
        transition: all 0.3s ease-in-out;
        margin-bottom: 0.5rem;
        padding: 2rem;
        
        @include breakpoint(sm){
            min-width: 350px;
            min-height: 400px;
            border: 1px solid $light;
            border-radius: $borderRadius;
            box-shadow: 0 0 35px rgba($dark, 0.5);
        }
    }
}

h1 {
    display: flex;
    align-items: center;
    font-size: x-large;
    margin-bottom: 1rem;

    .backArrow{
        cursor: pointer;
        margin-right: 0.5rem;
    }
}

.underline {
    cursor: pointer;
    text-decoration: underline;
    font-weight: 500;
}

form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    input[type="text"],
    input[type="email"],
    input[type="password"], 
    input[type="number"] {
        height: 40px;
        font-size: 16px;
        border-radius: 5px;
        border: 1px solid $dark;
        width: 100%;
        padding: 10px;
        margin: 10px 0;
    }
    
    input:focus {
        outline: none;
        border:1px solid $primary;
        box-shadow: 0 0 10px $light;
    }

    input[type="submit"] {
        background-color: $primary;
        color: $light;
        border: none;
        border-radius: 5px;
        padding: 10px;
        font-size: large;
        cursor: pointer;
        width: 100%;
        margin-top: 10px;
        transition: all 0.3s ease-in-out;

        &:hover {
            background-color: $dark;
        }
    }

    .passwordInputContainer {
        position: relative;
        align-items: center;
        width: 100%;
    }

    .eyeIcon {
        position: absolute;
        right: 12px;
        top: 20px;
        width: 21px;
        height: 21px;
        cursor: pointer;
    }

    .termsAndConditions {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: .1rem;
        font-size: 10px;
        color: $dark;

        p{
            a {
                color: $dark;
            }
        }
    }
}

.forgotPasswordWrraper {
    display: flex;
    width: 100%;
    justify-content: flex-end;
}
.forgotPasswordWrraper p {
    width: fit-content;
    cursor: pointer;
    color: #333;
    padding-top: .5rem;
    font-size: 14px;

    &:hover {
        text-decoration: underline;
    }
}

.newUserWrapper {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 1rem;
}

.orWrapper {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 1rem;

    .orLine {
        display: flex;
        align-self: center;
        width: 100%;
        height: 1px;
        background-color: $dark;
        border-radius: $borderRadius;
    }

    p {
        border: 1px solid $dark;
        padding: 0.3rem;
        border-radius: .5rem;
        font-weight: 600;
        color: $dark;
        font-size: 14px;
    }
}

.sosialMediaWrapper {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 1rem;

    .sosialMediaIcon {
        width: 40px;
        height: 40px;
        padding: 5px;
        margin: 0 0.5rem;
        border: 1px solid $dark;
        color: $dark;
        border-radius: 50%;
        cursor: pointer;
        transition: all 0.2s linear;

        &:hover {
            border: 1px solid $primary;
            color: $primary;
        }
    }
}