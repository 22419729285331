@import "./../../variables.scss";

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 1rem;
  min-height: calc(100vh - 157px);

  @include breakpoint(md) {
    flex-direction: row;
  }

  pre {
    white-space: pre-wrap;       /* Since CSS 2.1 */
    white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
    white-space: -pre-wrap;      /* Opera 4-6 */
    white-space: -o-pre-wrap;    /* Opera 7 */
    word-wrap: break-word;       /* Internet Explorer 5.5+ */
    font-family: $Poppins;
  }

  .title {
    font-size: 1.3em;
    font-weight: 500;
    display: flex;
    justify-content: center;
  }

  button {
    cursor: pointer;
    outline: none;
    padding: 0.75rem 1.25rem;
    border-radius: $borderRadius;
    font-size: 1rem;
    font-family: inherit;
    background: $primary;
    color: $dark;
    border: 1px solid transparent;
    transition: 0.3s ease all;
    min-width: 200px;
    align-self: center;

    &:hover {
      border-color: $primary;
      background: rgba($primary, 0.1);
      color: $primary;
    }
  }

  hr {
    border: 1px solid $primary;
    border-radius: 5px;
    margin: 0.5rem 0;
  }

  .priceBlock {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    &__price {
      font-size: xx-large;
      font-weight: 500;
      color: #3c9806;
      margin-right: 0.2rem;
    }
  }

  .listingBlock {
    display: flex;
    flex-direction: column;
    flex: 0.68;
    background-color: $bg;
    border-radius: 1rem;
    padding: 0.3rem 1rem;

    .imageBlock {
      //   background-color: aquamarine;

      &__mainImage {
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          border: 3px solid #fff;
          border-radius: 1rem;
          max-width: 350px;
          width: 350px;
          max-height: 250px;
          height: 250px;
          object-fit: cover;
        }
      }

      &__subImages {
        // background-color: lightpink;
        margin: 1rem 0;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
        justify-content: center;
        grid-auto-columns: 250px;
        grid-auto-rows: 150px;
        grid-gap: 1rem;

        img {
          border: 3px solid #fff;
          width: 100%;
          max-width: 250px;
          height: 100%;
          max-height: 250px;
          align-self: center;
          justify-self: center;
          border-radius: 1rem;
          object-fit: cover;
        }
      }
    }

    .detailBlock {
      display: flex;
      flex-direction: column;

      strong {
        font-weight: 600;
      }

      p {
        margin: 0.2rem 0;
      }
    }
  }

  .contactBlock {
    display: flex;
    flex-direction: column;
    flex: 0.3;
    border-radius: 1rem;
    padding: 1rem;
    background-color: $bg;

    strong {
      font-weight: 600;
    }

    .subTitle {
      font-size: 1.3em;
      font-weight: 500;
      display: flex;
      justify-content: center;
    }
  }
  .contactDetails {
    margin-top: 0.7rem;

    p {
      margin: 0.3rem 0;
    }
  }
}
