@import '../../variables.scss';

.container {
    margin: 1rem 0;
    display: flex;
    flex-direction: column;

    &_paymentsBlock {
        margin: 1rem;
    }
    
    button {
        cursor: pointer;
        outline: none;
        padding: 0.75rem 1.25rem;
        border-radius: $borderRadius;
        font-size: 1rem;
        font-family: inherit;
        background: $primary;
        color: $dark;
        border: 1px solid transparent;
        transition: 0.3s ease all;
        min-width: 200px;
        align-self: center;

        &:hover {
            border-color: $primary;
            background: rgba($primary, 0.1);
            color: $primary;
        }
    }
}