@import './../../variables.scss';

.container {
    min-height: calc(100vh - 213px);
    margin: 1rem;

    @include breakpoint(md) {
        // Desctop
        width: 90%;
        margin: auto;
    }

    h2 {
        font-weight: 600;
        color: $dark;
        text-align: center;
        padding-top: 0.6rem;
    }
    a {
        color: $dark;
    }

    ul {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 0 1rem;

        li {
            margin: 0.1rem 1rem ;
        }
    }

    .screenImage {
        padding: .3rem;
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 90%;
    }

    .button {
        cursor: pointer;
        outline: none;
        padding: 0.75rem 1.25rem;
        border-radius: $borderRadius;
        font-size: 1rem;
        font-family: inherit;
        background: $primary;
        color: $dark;
        border: 1px solid transparent;
        transition: 0.3s ease all;
        text-decoration: none;
        min-width: 150px;
    
        &:hover {
          border-color: $primary;
          background: rgba($primary, 0.1);
          color: $primary;
        }
      }

    &_bloks{
        display: flex;
        flex-direction: column;
        animation: fadeEffect 1.5s;
        transition: ease-out;

        @include breakpoint(md) {
            // Desctop
            flex-direction: row;
        }

        &_left {
            display: flex;
            align-items: center;
            flex-direction: column;
            margin: 1rem;

            @include breakpoint(md) {
                // Desctop
                flex: 1;
            }

            h3 {
                font-weight: 600;
                font-style: italic;
                margin: 1rem 0;
            }

            img {
                width: 60%;

                @include breakpoint(md) {
                    // Desctop
                    width: 80%;
                }
            }
        }

        &_right {
            margin: 1rem;

            @include breakpoint(md) {
                // Desctop
                flex: 2;
            }

            p {
                padding: 0.2rem 0;
                text-align: justify;
            }

            &_btnWrapper {
                display: flex;
                justify-content: space-around;
                margin: 1rem 0;
            }
        }
    }

    @keyframes fadeEffect {
        from {opacity: 0;}
        to {opacity: 1;}
    }
    
}

